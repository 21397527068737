import React from "react";

// Customizable Area Start

import { View, StyleSheet } from "react-native";
import {
  FormControl,
  Grid,
  Input, TextareaAutosize,
  Button,
  Typography, Box,
} from "@mui/material";
import { deviceHeight } from "../../../framework/src/Utilities";
import { header, footer, logo, mailLogo } from "./assets";
import { Formik, Form } from "formik";
import { object, string } from "yup";
import HelpCentreController, {
  Props,
} from "./HelpCentreController";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu";

const validationSchema = object({
  full_name: string()
    .required('Full name is required.')
    .min(2, 'Full name must be at least 2 characters long.'),
  email: string()
    .email('Invalid email address')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
    .required('Please enter an email address.'),
  message: string()
    .required('Message is required.')
    .min(10, 'Message must be at least 10 characters long.'),
});
// Customizable Area End

export default class HelpCenterBlock extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start 

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <NavigationMenu navigation={this.props.navigation} id={""}>
        <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
          <Grid item xs={12} md={8}>
            <View style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
              <Grid container direction="column">
                <Grid item xs={4} sx={{ backgroundColor: "white", display: 'flex', width: "100%" }} justifyContent={'center'} alignItems={'center'}  >

                </Grid>
                <Grid item xs={8} style={{ padding: 5 }}>
                  <div>{this.state.isSubmitted ? <View style={{
                    display: "flex",
                    paddingTop: "20px", padding: 10
                  }}>
                    <Typography sx={{
                      fontSize:"24px",
                      flex: 1,
                      padding: "15px 0 26px 0", fontWeight: "700"
                    }}>
                      Contact Us
                    </Typography>
                    <Formik
                      initialValues={this.state.formValues}
                      validationSchema={validationSchema}
                      onSubmit={(values) => { this.setState({ formValues: values }); this.handleSubmit() }}
                    >
                      {({ values, handleChange, resetForm, handleBlur, errors, touched }) => (
                        <Form>
                          <View style={{
                            flex: 1,
                            marginBottom:"20px"
                          }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: 700, marginBottom: 1 }} component="h1" style={styles.textFont}>
                              Full Name
                            </Typography>
                            <FormControl fullWidth required>
                              <Input
                                name="full_name"
                                type="text"
                                value={values.full_name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disableUnderline
                                sx={{
                                  backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                                  border: '1px solid #ccc',
                                  borderColor: this.validation("full_name", touched, errors) ? "red" : "#ccc"
                                }}
                                data-test-id="txtInputEmail"
                              />
                              <span style={{ color: "red", fontFamily: "sans-serif", fontSize: "12px" }}>{errors.full_name}</span>
                            </FormControl>
                          </View>
                          <View style={{
                            flex: 1,
                            padding: "0 20px",
                            marginBottom:"20px"
                          }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: 700, marginBottom: 1 }} component="h1" style={styles.textFont}>
                              Email Address
                            </Typography>
                            <FormControl error={this.state.error} fullWidth required>
                              <Input
                                name="email"
                                type="email"
                                value={values.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disableUnderline
                                sx={{
                                  backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                                  border: '1px solid #ccc',
                                  borderColor: this.validation("email", touched, errors) ? "red" : "#ccc"
                                }}
                                data-test-id="txtInputEmail"
                                
                              />
                              <span style={{ color: "red", fontFamily: "sans-serif", fontSize: "12px" }}>{errors.email}</span>
                            </FormControl>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              marginBottom:"20px"
                            }}
                          ><FormControl>
                              <Typography sx={{ fontSize: "16px", fontWeight: 700, marginBottom: 1 }} component="h1" style={styles.textFont}>
                                Message
                              </Typography>
                              <TextareaAutosize name="message" value={values.message}
                                onBlur={handleBlur}
                                minRows={5}
                                placeholder="700 characters or less"
                                onChange={handleChange} maxRows={6} style={{
                                  backgroundColor: "white",font:"inter",border: '1px solid #ccc', borderRadius: "8px", padding: '10px', resize: 'vertical',
                                   borderColor: this.validation("email", touched, errors) ? "red" : "#ccc",
                                  fontFamily:"Helvetica"
                                }} />
                              <span style={{ color: "red", fontFamily: "sans-serif", fontSize: "12px" }}>{errors.message}</span>
                            </FormControl>
                          </View>
                          <View
                            style={{
                              flex: 1,
                            }}
                          >
                            <Button type='submit' sx={[{
                              textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px",
                              fontWeight: "700",
                              marginTop:"12px",
                              height: "44px",
                              '&:hover': {
                                backgroundColor: '#FFC300',
                              },
                            }]}

                            >Send</Button>

                            <Button type="reset"  data-test-id="clearField" onClick={this.clearFieldHandler} sx={{color:" #1D4ED8",mt:2,textTransform:"capitalize",fontSize:"12px"}} variant="text">Go Back</Button>
                          </View>
                        </Form>
                      )}
                    </Formik>
                  </View> : <View style={{
                    display: "flex",
                    paddingTop: "20px", padding: 10
                  }}>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography sx={{
                        flex: 1,
                        fontSize: "24px",
                        lineHeight: '32px',
                         font: "Inter",
                        padding: "20px", fontWeight: "700"
                      }}>
                        Thank you
                        for contacting us.
                      </Typography>
                      <img
                        src={mailLogo.default}
                        alt="SVG Icon"
                        style={{
                          width: "64px",
                          height: "64px",
                          // top: 48px;
                          // gap: 0px;
                          // opacity: 0px;

                          alignSelf: "center"
                        }} />
                      <Typography sx={{color:"#2d2a24",font:"inter",marginTop:"12px"}}>We've received your message and We'll be in touch shortly whit you.</Typography>
                      <Button data-test-id="backbtn" onClick={() => {
                        this.setState({
                          isSubmitted: true
                        })
                      }} sx={[{
                        width: "100%",
                        margin: "20px 0",
                        textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px",
                        fontWeight: "700",
                        height: "44px",
                        '&:hover': {
                          backgroundColor: '#FFC300',
                        },
                      }]}

                      >Go Back</Button>
                    </Box>
                  </View>

                  }</div>

                </Grid>
              </Grid>
            </View>
          </Grid>
        </Grid>
      </NavigationMenu>
      // Customizable Area End
    );
  }

}
// Customizable Area Start

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica",
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px"
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent"
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  footerText: {
    textAlign: "center",
    margin: 5
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  }
});

// Customizable Area End
