import React from "react";


// Customizable Area Start

import { View, StyleSheet } from "react-native";

import { brush, more, profile, search } from "./assets";
import {
  Grid,Typography,Modal,Box,Input,InputAdornment, IconButton,Button, MenuItem, FormControl, OutlinedInput, Select, FormHelperText,
  Checkbox
} from "@mui/material";
import { deviceHeight } from "../../../framework/src/Utilities";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {  referrals, settings } from "../../landingpage/src/assets";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import Rolesandpermissions2Controller, {
  Props,
  configJSON,
} from "./Rolesandpermissions2Controller";

export default class Rolesandpermissions2 extends Rolesandpermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showError=()=>{
   return this.state.error && <FormHelperText sx={{ marginLeft: 1 }}>{this.state.errorMessage}</FormHelperText>
   
  }
  showEmailError=()=>{
    return this.state.errorEmail && <FormHelperText sx={{ marginLeft: 1 }}>Please enter valid email address.</FormHelperText>
  }
  noResult=()=>{
    return this.state.enableAdd &&<Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", }}> <Typography>No search results found</Typography></Box>
  }
  showEditForm(){
   return this.state.operationType==="EditForm" &&
      <Grid item xs={8} style={{ padding: 5 }}>
        <View style={styles.edit1}>
         
          <View style={{
            flex: 1,
            padding: "12px"
          }}>
            <View style={{flexDirection:"row",flex:1,marginBottom:12}}>
            <Button data-test-id="goBackButton2E" sx={{ textTransform: 'none',}} onClick={()=>this.gobackIn()}>
            <KeyboardArrowLeftIcon style={{marginLeft:-3}}/>
            <Typography fontWeight={400} sx={{fontSize:"14px",color:"#1D4ED8",}}>Go Back</Typography>
            </Button>
            </View>
           
    
    <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Edit Outreach Worker</Typography>
    <Typography fontWeight={700} sx={{fontSize:"18px",lineHeight:"26px",marginTop:1,marginBottom:1}}>Acacia Network</Typography>
 

          </View>
          <View style={{
            display: "flex",
            paddingTop: "20px", padding: 10
          }}>
          <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                First Name
              </Typography>
                <Input
                  type="text"
                  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="txtInputfirst"
                  // placeholder={"First Name"}
                  value={this.state.selectedResult.first_name}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      first_name: e.target.value,  
                    },
                  }))}
                 
                />

            <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Last Name
              </Typography>
                <Input
                  type="text"
                  disableUnderline
                  value={this.state.selectedResult.last_name}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      last_name: e.target.value,  
                    },
                  }))}
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="txtInputlast"
                 />
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Status
              </Typography>

                
             
              <FormControl variant="outlined" fullWidth>
              <Select 
              input={<OutlinedInput notched={false} />}
                       displayEmpty
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px",  height: "44px",
                     borderColor:  "#ccc",marginTop:1,marginBottom:3,justifyContent:"center"
                  }}
                  data-test-id="txtInputStatus"
                  label="Select a status"
                  value={this.state.selectedResult.status}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      status: e.target.value,  
                    },
                  }))}
                >
                 <MenuItem  value="" disabled>
                   Select a status
                    </MenuItem>
                        <MenuItem value={'Active'}> <CheckCircleOutlineIcon sx={{fontSize:"24px"}} style={{ fill: "green",marginRight:10,}} />  Active</MenuItem>
                        <MenuItem value={'Inactive'}> <HighlightOffIcon sx={{fontSize:"24px"}} style={{ fill: "red",marginRight:10 }} />Inactive</MenuItem>
                      </Select>
          </FormControl>
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Phone Number
              </Typography>
                <Input
                  type="text"
                  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="txtInputPhone"
                  value={this.state.selectedResult.phone_number}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      phone_number: e.target.value,  
                    },
                  }))}
                />
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Email Address
              </Typography>
                <Input
                  type="email"
                  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="txtInputEmail1"
                  value={this.state.selectedResult.email}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      email: e.target.value,  
                    },
                  }))}
                />

           

          <Button sx={[{
                textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px", fontWeight: "700",
                height: "44px",
                '&:hover': {
                  backgroundColor: '#FFC300',
                },
              }]}
                data-test-id={"btnSaveEdit"}
                onClick={()=>this.editWorker()}
              >Save</Button>
             </View>
         
        </View>
      </Grid>
  }
  showAddForm(){
    return  this.state.operationType==="AddForm" && 
      <Grid item xs={8} style={{ padding: 5 }}>
        <View style={{
          display: "flex",
          paddingTop: "20px", padding: 10
        }}>
         
          <View style={{
            flex: 1,
            padding: "12px"
          }}>
            <View style={{flexDirection:"row",flex:1,marginBottom:12}}>
            <KeyboardArrowLeftIcon style={{marginLeft:-3}}/>
             <Button data-test-id="goBackButton2" sx={{ textTransform: 'none',}} onClick={()=>this.gobackIn()}>
            <Typography fontWeight={400} sx={{fontSize:"14px",color:"#1D4ED8"}}>Go Back</Typography>
            </Button>
            </View>
           
    
    <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Add Outreach Worker</Typography>
    <Typography fontWeight={700} sx={[{fontSize:"18px",lineHeight:"26px",}, styles.subText]}>Acacia Network</Typography>
 

          </View>
          <View style={[{paddingTop: "20px", },styles.addui]}>
          <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                First Name
              </Typography>
                <Input type="text"  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="txtInputnameAdd"
                  
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      first_name: e.target.value,  
                    },
                  }))}
                  
                />

            <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Last Name
              </Typography>
                <Input type="text"
                  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="lastNameAdd"
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      last_name: e.target.value,  
                    },
                  }))}
                 />
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Status
              </Typography>
              <FormControl variant="outlined" fullWidth>
              <Select 
               value={this.state.selectedResult.status}
              onChange={(e) => this.setState((prevState) => ({
                selectedResult: {
                  ...prevState.selectedResult, 
                  status: e.target.value,  
                }
              }))}
              input={<OutlinedInput notched={false} />}
                       displayEmpty
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px",paddingBottom: '10px', paddingTop: '10px', height: "44px",
                     borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="txtSelectAdd"
                  label="Select a status"
                  
                 
                >
                   <MenuItem  value="" disabled>
                   Select a status
                    </MenuItem>
                    <MenuItem value={'Active'}> <CheckCircleOutlineIcon sx={{fontSize:"24px"}} style={{ fill: "green",marginRight:10,}} />  Active</MenuItem>
                        <MenuItem value={'Inactive'}> <HighlightOffIcon sx={{fontSize:"24px"}} style={{ fill: "red",marginRight:10 }} />Inactive</MenuItem>
                     
                      </Select>
          </FormControl>
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Phone Number
              </Typography>
              <FormControl sx={{marginBottom:3}} error={this.state.error} fullWidth>
                <Input 
                  type="phone"
                  inputProps={{ maxLength: 10 }}
                  disableUnderline
                  onChange={this.handleChangePhone}
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                  }}
                  data-test-id="txtInputPhoneAdd"
             
                />
               {this.showError()}
                </FormControl>
                
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Email Address
              </Typography>
              <FormControl sx={{marginBottom:3}} error={this.state.errorEmail} fullWidth>
                <Input
                  type="email"
                  disableUnderline
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      email: e.target.value,  
                    },errorEmail:false
                  }))}
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1
                  }}
                  data-test-id="txtInputEmailAdd"
                 
                  onBlur={() => this.handleBlur()}
                />
                {this.showEmailError()}
                </FormControl>

              <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
              Temporary Password
              </Typography>
                <Input sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid',borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  disableUnderline
                  data-test-id="txtInputPassword"
                  type={this.state.enablePasswordField ? 'password' : 'text'}
                  placeholder={"Create a Password"}
                  value={this.state.password}
                  onChange={this.setPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        data-test-id="togglePasswordButton"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                      >
                        {this.state.enablePasswordField ? <VisibilityOffOutlinedIcon style={{ fill: "grey" }} /> : <VisibilityOutlinedIcon style={{ fill: "grey" }} />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

          <Button sx={[{
                textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px", fontWeight: "700",
                height: "44px",
                '&:hover': {
                  backgroundColor: '#FFC300',
                },
              }]}
                data-test-id={"btnSaveAdd"}
                onClick={()=>this.addWorker()}
              >Save</Button>
             </View>
         
        </View>
      </Grid>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu navigation={undefined} id={""}>
      <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
      
      <Grid item xs={12} md={8}>
        <View style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <Grid container direction="column"  >
         
            {this.state.operationType==="mainForm" ? 
            <Grid item xs={8} style={{ padding: 5 }}>

              <View style={{
                display: "flex",
                paddingTop: "20px", padding: 10
              }}>
               
                <View style={{
                  flex: 1,
                  padding: "12px"
                }}>
                  <View style={{flexDirection:"row",flex:1,marginBottom:12}}>
                  <Button data-test-id="goBackButton" sx={{ textTransform: 'none',}} onClick={()=>this.goback("LandingPage")}>
                  <KeyboardArrowLeftIcon style={{marginLeft:-3,color:"black"}}/>
                
                  <Typography fontWeight={400} sx={{fontSize:"14px",color:"#1D4ED8",}}>Go Back</Typography>
                  </Button>
                  </View>
                 
          
          <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Maintenance</Typography>
          <Typography fontWeight={700} sx={{fontSize:"18px",lineHeight:"26px",marginTop:1,marginBottom:1}}>Acacia Network</Typography>
         <Typography fontWeight={400} sx={{fontSize:"14px",lineHeight:"22px"}}>Add/Edit your users and organization</Typography>
       

                </View>
                <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
          <div style={{height:3,width:3}}>  <img src={profile.default} style={{height:"56px"}}  /></div>
          </Grid>
          <Grid item xs={8}>
          <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography fontWeight={700} sx={{ fontSize: "14px",  font:"Inter",fontWeight:700}}>Profile Manager</Typography></Grid>
              <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px", font:"Inter",fontWeight:400}}>You can add/edit your Outreach Workers</Typography></Grid>
            </Grid>
          </Grid>
          <Grid data-test-id="openModalButton" onClick={()=>this.openModal()} item xs={2}>
            <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={settings.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>

         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
            <img src={referrals.default} style={{height:"56px"}}  />
          </Grid>
          <Grid item xs={8}>
            <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography fontWeight={700} sx={{ fontSize: "14px", font:"Inter",}}>Edit Organization</Typography></Grid>
              <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px",font:"Inter",}}>You can edit and manage your Organization</Typography></Grid>
            </Grid>
          </Grid>
          <Grid data-test-id="openEditModalButton" item xs={2} onClick={()=>this.getAllServices()}>
            <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={settings.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>
               
              </View>
            </Grid>:null}
            {this.showAddForm()}
           
            {this.showEditForm()}
            
          </Grid>
        </View>
      </Grid>
      <Grid sx={{
        height: { md: deviceHeight, l: deviceHeight }, backgroundColor: '#333',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        justifyContent: "center",
        alignItems: "center",
        textAlignVertical: "center",
        alignContent: "center"
      }} item xs={12} md={4}>
     
        <Modal
        open={this.state.open}
        onClose={()=>this.handleClose()}
        data-test-id={"btnModal"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[styles.modal,{borderRadius:"8px",boxShadow: 24,}]}>
         
        <CloseIcon data-test-id="btnModalIcon" sx={{height:"20px",marginLeft:"93%"}} onClick={()=>this.handleClose()} />
       
        <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Profile Manager</Typography>
        <Typography fontWeight={700} sx={{fontSize:"18px",lineHeight:"26px",marginTop:1,marginBottom:1}}>Acacia Network</Typography>
        <Typography fontWeight={400} sx={{fontSize:"16px",lineHeight:"26px",marginTop:1.5,marginBottom:0.5}}>Add/Edit Profile</Typography> 
        <Grid container sx={{ display: "flex"}} direction={"row"} flexDirection={"row"}>
          <Grid item xs={9} >  
            <View style={styles.input}>
        <Input
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px",  
                        paddingTop:0.5
                        }}
                        disableUnderline
                        data-test-id="txtInputSearch"
                        type={ 'text'}
                        placeholder={"Profile Search"}
                        onChange={(text:React.ChangeEvent<HTMLInputElement>)=>this.searchFilterFunction(text.target.value)}
                        value={this.state.searchtext}
                        startAdornment={
                          <InputAdornment sx={{marginBottom:0.5}} position="end">
                           
                              <img style={{height:"21.01px",marginRight:8}} src={search.default} />
                          
                          </InputAdornment>
                        }
                      />
                        <img data-test-id="imageClick" onClick={()=>this.clear()} style={{height:"21.01px",marginRight:5}} src={brush.default} />
                      </View>
          </Grid>
          <Grid item xs={2} sx={{paddingLeft:2}}>
           <Button disabled={!this.state.enableAdd} onClick={()=>this.addClicked()} data-test-id={"btnAdd"} sx={[{backgroundColor:!this.state.enableAdd? "#E2E8F0 ":"#F5C502",width:"40px",height:"44px",color:"black", textTransform: 'none',fontWeight:"700",fontSize:"16px",'&:hover': {
                       color:"#64748B", fontWeight:"700",fontSize:"16px"
                      },}]}>Add</Button>
          </Grid>
      
                      </Grid>
                      {this.noResult()}
                     {this.state.searchResult.length > 0 && <Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", }}>
                      {this.state.searchResult.map((item:{first_name:string,last_name:string,id:number,status:string,phone_number:string,email:string}, index) => (
                      
                       <Grid data-test-id={"btnSelect"} sx={{backgroundColor:this.state.selectedResult.id===item.id ? "#FEF3C7":'',margin:0.5,padding:1,borderRadius: "8px", }} onClick={()=>this.selectResult(item)}>
                           <Typography sx={{marginLeft:1,fontWeight:400,fontSize:"16px"}}>{item.first_name} {item.last_name}</Typography>
                        </Grid>
                       
                    ))}
                        </Box>}
                     
                     
                      <View style={{backgroundColor:"#D9D9D9",height:"2px",marginVertical:5}} />
                      {this.state.selectedResult.id>0 && 
                      <Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", }}>
                        <Grid sx={{backgroundColor:'',margin:0.5,padding:1,borderRadius: "8px", }} >
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>First Name</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedResult.first_name}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Last Name</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedResult.last_name}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Status</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedResult.status}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Phone Number</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedResult.phone_number}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Email</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedResult.email}</Typography>
                        </Grid> 
                      </Box>
                      }

                      <Button sx={[{paddingLeft:"45%",paddingRight:"45%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none', backgroundColor:this.state.selectedResult.id>0? "#F5C502":"#E2E8F0", color: this.state.selectedResult.id>0?"black":"#64748B", font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                      data-test-id={"btnEditButton"}
                      disabled={!this.state.selectedResult}
                      onClick={()=>this.editClicked()}
                    >Edit</Button>
        </Box>
      </Modal>
      <Modal
        open={this.state.successModal}
        onClose={()=>this.setState({successModal:false})}
        data-test-id={"btnModal1"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box alignContent={"center"} sx={[styles.modal,{
              overflowY: 'auto',borderRadius:"8px",boxShadow: 24,justifyContent:"center",width:"327px",alignItems:"center"}]}>
        <CloseIcon data-test-id="btnModalIcon1" sx={{height:"20px",marginLeft:"93%"}} onClick={()=>this.setState({successModal:false})} />
          <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px",textAlign:"center"}}>{this.state.title}</Typography>
         {this.state.success? <CheckCircleOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "green",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> 
          :<ErrorOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "red",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> }
          <Typography fontWeight={400} sx={{fontSize:"14px",lineHeight:"22px",textAlign:"center",marginTop:3}}>{this.state.subTitle}</Typography>
        </Box>
      </Modal>
      <Modal
        open={this.state.openEditOrg}
        onClose={()=>this.handleCloseOrg()}
        data-test-id={"btnModal2"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[styles.modal,{borderRadius:"8px",boxShadow: 24,backgroundColor:"#F6F6F3",marginLeft:"10%",marginRight:"10%",maxHeight: '80vh', 
          overflowY: 'auto',  }]}>
         
        <CloseIcon data-test-id="btnModalIcon2" sx={{height:"20px",marginLeft:"93%"}} onClick={()=>this.handleCloseOrg()} />
       
        <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Edit Organization</Typography>
     
                      <Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", }}>
                        <Grid sx={{borderRadius: "8px", }} >
                        <Typography sx={[{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Organization Name </Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.orgnization.organization_name}</Typography>
                        <Typography sx={[{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Organization Address</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.orgnization.address}</Typography>
                        <Typography sx={[{marginTop:1,color:"#2D2A24",fontWeight:"700",fontSize:"16px",font:"Inter"}]}>Contact Person </Typography>
                        <Input
                        type="text"
                        disableUnderline
                        value={this.state.orgnization.contact_person}
                        onChange={(e) => this.setState((prevState) => ({
                          orgnization: {
                            ...prevState.orgnization, 
                            contact_person: e.target.value,  
                          },
                        }))}
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",width:"271px",
                          border: '1px solid ', borderColor:  "#ccc",marginTop:1
                        }}
                        data-test-id="txtInputlast1"
                       />
                        <Typography sx={[{marginTop:1,color:"#2D2A24",fontWeight:"700",fontSize:"16px",font:"Inter"}]}>Contact Phone </Typography>
                       <FormControl variant="outlined" fullWidth>
                        <Input
                        type="text"
                        disableUnderline
                        value={this.state.orgnization.organization_phone}
                        onChange={(e) => this.setState((prevState) => ({
                          orgnization: {
                            ...prevState.orgnization, 
                            organization_phone: e.target.value,  
                          },
                        }))}
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                        }}
                        data-test-id="txtInputlast2"
                       />
                       </FormControl>
                       <Grid>
                        <Typography sx={[{marginTop:1,color:"#2D2A24",fontWeight:"700",fontSize:"16px",font:"Inter"}]}>Contact Email</Typography>
                        <FormControl variant="outlined" fullWidth>
                        <Input
                        type="text"
                        disableUnderline
                        value={this.state.orgnization.organization_email}
                        onChange={(e) => this.setState((prevState) => ({
                          orgnization: {
                            ...prevState.orgnization, 
                            organization_email: e.target.value,  
                          },
                        }))}
                        
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                        }}
                        data-test-id="txtInputlast3"
                       />
                       </FormControl>
                       </Grid>
                        </Grid> 
                      </Box>

                      <View style={{backgroundColor:"#D9D9D9",height:"2px",marginVertical:5}} />
                      <Typography sx={[styles.selectedTitle,{fontWeight:"700",fontSize:"18px",font:"Inter"}]}>Services Offered </Typography>
                      
                      <Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", }}>
                     

                    
{this.state.allServices.map((item:{
  sub_services: any;id:string,checked: boolean | undefined;attributes:{
  name:string
}}, index) => (
                        <View data-test-id="View">
                       
                       {item.attributes.name !== "Other Services Offered" ?
                     <Box>
                     <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{item.attributes.name}</Typography>
                        <Input
                        type="text"
                        disableUnderline
                        value={"Services"}
                        readOnly
                        onClick={()=>this.getServices(item.id)}
                        
                        sx={{
                          backgroundColor: "white", borderWidth: 4, borderRadius: "8px",paddingBottom: '10px', paddingTop: '10px', height: "44px",
                          borderColor:  "red",marginTop:1,marginBottom:3, '& .MuiInputBase-root': {
        border: '2px solid red',  
        borderRadius: '8px',      
      },
      '& .MuiInputBase-input': {
        padding: '0px 10px', 
      },
                        }}
                        data-test-id="txtInputService"
                        endAdornment={
                          <InputAdornment position="end">
                          <img src={more.default} style={{height:"6"}}  />
                          </InputAdornment>
                        }
                       />
                      
                       {this.state.isModalOpen === item.id && (
        <div  style={webStyles.modalContent}>
          <div  >
            <ul>
              {this.state.Services.map((option) => (
                    <Box data-test-id="checkBoxId" sx={[webStyles.itemsMainBox,{borderColor:  "#ccc",borderWidth: 1, borderRadius: "8px"}]} onClick={() => this.handleToggle(index,option.id,'')}>
                    <Box sx={webStyles.checkboxContainer1}>
                      <Checkbox
                        edge="start"
                        checked={option.checked}
                        tabIndex={-1}
                        disableRipple
                        
                        sx={{
                          color: "#64748B",
                          borderRadius: "6px",
                          '&.Mui-checked': {
                            color: "#CF9500",
                          },
                        }}
                      />
                      <Typography style={webStyles.listItemName}>{option.attributes.name}</Typography>
                    </Box>
                 

                  </Box>
              ))}
            </ul>
          </div>
        </div>
      )}

               </Box>  :
         <View>
         <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{item.attributes.name}</Typography>
           <Grid container flexDirection={"row"} >
           <Box data-test-id="checkBoxId1" sx={[webStyles.itemsMainBox,{borderColor:  "#ccc",borderWidth: 1, borderRadius: "8px"}]} onClick={() => this.setData(item.id)}>
           <Box sx={webStyles.checkboxContainer}>
                       <Checkbox
                       data-test-id="checkBoxId12"
                         edge="start"
                         checked={item.checked}
                         tabIndex={-1}
                         disableRipple
                         onClick={()=>this.setData(item.id)}
                         sx={{
                           color: "#64748B",
                           borderRadius: "6px",
                           '&.Mui-checked': {
                             color: "#CF9500",
                           },
                         }}
                       />
                       <Typography sx={[webStyles.listItemName,{marginTop:"11px"}]}>{item.attributes.name}</Typography>
                       </Box></Box>
                     </Grid>
                     </View>      }
                        </View>
                      ))}
          
                        </Box>

                      <Button sx={[{paddingLeft:"45%",paddingRight:"42%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none', backgroundColor: "#F5C502", color: "black", font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                      data-test-id={"btnEdit"}
                      disabled={!this.state.selectedResult}
                      onClick={()=>this.updateOrganization()}
                    >Save</Button>
        </Box>
      </Modal>
      </Grid>
    </Grid>
    </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  modalOverlay: {
    position: "fixed" as "fixed",  
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  modalContent: {
    backgroundColor: "white",
    padding: "20px",
    width: "80%",
    maxWidth: "500px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    maxHeight: "80vh",
    overflowY: "auto" as "auto",
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  goBackButton: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'none',
    gap:"8px",
  },
  goBackText: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#1D4ED8',
    lineHeight: '22px',
  },
  comunityType: {
    color:"#2D2A24",
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing:"-0.05%",
    marginTop:"16px"
  },
  areaText: {
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    margin:"32px 0 8px 0"
  },
  inputDefaultLabel: {
    color:"#94A3B8",
    fontSize:"16px",
    fontWeight:400
  },
  formControl: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height:"44px",
  },
  menuItems: {
    backgroundColor: '#ffffff',
    borderBottom: "1px solid #CBD5E1",
    color:"#2D2A24",
    fontSize:"14px",
    fontWeight:700,
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  menuItemsSecond: {
    fontWeight:700,
    backgroundColor: '#ffffff',
    fontSize:"14px",
    color:"#2D2A24",
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  mapButton: {
    height:"44px",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    textTransform:"capitalize",
    borderRadious:"8px",
    marginTop:"32px",
  },
  submitButton: {
    marginTop:"24px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
  },
  listItemName: {
    fontWeight:700,
    fontSize:"14px",
    color:"#2D2A24",
  },
  listItemQuantity: {
    fontWeight:400,
    fontSize:"16px",
    color:"#2D2A24",
  },
  itemsMainBox: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%"
  },
  checkboxContainer: {
    justifyContent:"space-between",
    display:"flex",
    alignItems:"center",paddingLeft:-15
  },
  checkboxContainer1: {
    justifyContent:"space-between",
    display:"flex",
    alignItems:"center",
  },
  selectMenu: {
    height: "44px",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
  },
  estimatedItems: {
    color:"#2D2A24",
    fontWeight:400,
    fontSize:"16px",
  },
  estimatedBox: {
    height:"44px",
    justifyContent:"space-between",
    alignItems:"center",
    display:"flex",
    marginTop:"8px",
    padding:"0px 32px"
  },
  totalItems: {
    fontWeight:700,
    fontSize:"16px",
    color:"#2D2A24",
  },
  uploadButtonBox: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  photoCamera: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  cameraIcon: {
    color: "#2D2A24",
    fontSize: "24px"
  },
  addPictureText: {
    marginLeft: '16px',
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:400
  },
  pendingImageBox: {
    height: "48px",
    width: "48px",
    borderRadius: "6px",
    backgroundColor: '#FFFDC1'
  },
  defaultImageIcon:{
    color:"#F5C502",
    fontSize: "20px"
  },
  uploadButton: {
    backgroundColor: '#F5C502',
    height:"44px",
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:700,
    textTransform:"capitalize",
  },
  checkBoxMenu: {
    height: "44px",
  },
  serviceSelect: {
    height:"44px",
    fontSize:"16px",
    fontWeight:700,
  },
  umbrellaImageBox: {
    backgroundColor: "white",
    display: 'flex',
    width: "100%"
  },
  umbrellaImage: {
    width: "375px",
    height: "240px",
    padding: "20px",
    alignSelf: "center"
  },
  addPictureHeadingText: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#2D2A24"
  },
  tableContainerBox: {
    boxShadow: 'none',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
  },
  addPictureIcon: {
    position: 'absolute',
    right: "4.51px",
    top: "4.51px"
  },
  footerGridBox: {
    height: "auto",
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    justifyContent: "center",
    alignItems: "center",
    textAlignVertical: "center",
    alignContent: "center"
  },
  footerLogo: {
    width: "190.02px",
    height: "26.47px",
    backgroundColor: "#F5C502",
    borderRadius: 20,
    marginLeft: 10, 
    margin: 5,
    paddingLeft: 1, 
    marginBottom: 5
  },
  footerView: {
    height: 1, 
    backgroundColor: "#F5C502", 
    paddingHorizontal: 20
  }
};
const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica",
    marginHorizontal:15
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px"
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent"
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  footerText: {
    textAlign: "center",
   
    margin: 1
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  modal:{
    position: 'absolute',
  top: '10%',
  margin:3.5,
  backgroundColor:"#F6F6F3",
  padding:2,
  maxHeight: "80%", 
  
  },
  input:{
    flexDirection:"row",justifyContent:"space-between", shadowColor: '#FCE38B',
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 0 }, 
    shadowRadius: 10,  
    elevation: 8, 
    borderWidth: 1,
    borderColor: '#D97706', 
    borderRadius: 10,alignContent:"center",alignItems:"center",padding:2,backgroundColor:"white"
     },
    selectedTitle:{marginLeft:1,marginTop:1,color:"#2D2A24"},
    selectedValue:{marginLeft:2,marginBottom:1,color:"#565047"},
    subText:{marginTop:1,marginBottom:1},
    edit1:{
      display: "flex",
      paddingTop: "20px", padding: 10
    },
    addui:{display: "flex",padding: 10}
 
});


// Customizable Area End
