import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface Item {
  id:number;
  name: string;
  checked: boolean;
  inputValue?: string;
}

interface NestedItem {
  id:number;
  name:string;
  checked:boolean;
}

interface File {
  name: string;
  size: number;
  status: string;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  item: Item[];
  nestedCheckboxes: NestedItem[];
  isModalOpen:boolean;
  isImageModalOpen:boolean;
  isRemoveImageModalOpen: boolean,
  isViewImageModalOpen: boolean,
  selectedFile: File | null;
  uploadedFiles: { name: string; size: number; status: string }[];
  errorMessage: string;
  viewImageUrl: string | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class AdministratedServicesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      item:[
        {id:1, name:"Narcan Performed on Person",checked:false,inputValue: ""},
        {id:2, name:"Wound Care",checked:false,inputValue: ""},
        {id:3, name:"CPR",checked:false,inputValue: ""},
        {id:4, name:"Opioid Response",checked:false,inputValue: ""},
        {id:5, name:"Called for Emergency Services",checked:false,inputValue: ""},
        {id:6, name:"DOHMH Drug Testing Machines",checked:false,inputValue: ""},
      ],
      nestedCheckboxes: [
        {id:1, name:"On Point NYC(PNNY)",checked:false},
        {id:2, name:"Boom!Health",checked:false},
        {id:3, name:"SACHR",checked:false},
      ],
      isModalOpen: false,
      isImageModalOpen: false,
      isRemoveImageModalOpen: false,
      isViewImageModalOpen: false,
      selectedFile: null,
      uploadedFiles: [],
      errorMessage: "",
      viewImageUrl: null,
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  handleToggle = (index: number) => {
    this.setState((prevState) => {
      const updatedItems = prevState.item.map((item, i) =>
        i === index ? { ...item, checked: !item.checked } : item
      );
      return { item: updatedItems };
    });
  };

  handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    this.setState((prevState) => {
      const updatedItems = prevState.item.map((item, i) =>
        i === index ? { ...item, inputValue: value } : item
      );
      return { item: updatedItems };
    });
  };

  handleNestedCheckboxToggle = (index: number) => {
    this.setState((prevState) => {
      const updatedNestedCheckboxes = prevState.nestedCheckboxes.map((nestedCheckbox, i) =>
        i === index ? { ...nestedCheckbox, checked: !nestedCheckbox.checked } : nestedCheckbox
      );
      return { nestedCheckboxes: updatedNestedCheckboxes };
    });
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleOpenImageModal = () => {
    this.setState({ isImageModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleCloseImageModal = () => {
    this.setState({ isImageModalOpen: false });
  };
  
  // Customizable Area End
}
