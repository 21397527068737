import React from "react";

//Customizable Area Start
import {
  View,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
} from "react-native";
import {
  FormControl,
  Grid,
  Input,
  FormHelperText,
  Button,
  Typography,Box
} from "@mui/material";
import { deviceHeight } from "../../../framework/src/Utilities";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { footer, header, logo, mail } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation } = this.props;

    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            this.isPlatformWeb() ? styles.containerWeb : styles.containerMobile
          }
        >
          <TouchableWithoutFeedback onPress={() => this.hideKeyboard()}>
            {/* Customizable Area Start */}
            <NavigationMenu navigation={undefined} id={""}>
            <Grid container sx={{flexDirection:{md:"row-reverse"},
            backgroundColor:"#f3f3f1"}}>
            
        <Grid item xs={12} md={8}>
        <View style={styles.body}>
          <Grid container direction="column"  >
        
        <Grid item xs={8} >
       {this.state.accountStatus==="main" ?
          <View style={styles.formWrapper}>
          
            <View style={styles.inputWrapper}>
            <Typography component="h6" sx={{fontSize:"24px",
              marginBottom:"15px",marginRight:3,
              fontWeight:"700"}} >
            Forgot password
          </Typography>
          <Typography sx={{marginBottom:"20px",fontSize:"14px"}} 
          style={styles.paraText}>
          We’ll send you a link to reset your password. If you don’t receive an email, please check your spam folder.
          </Typography>
          {this.state.loginFailMsg.length > 0 &&
                      <Box sx={{
                        backgroundColor: '#ffe5e5',  color: '#d32f2f',
                        padding: '10px 16px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        borderLeft: '4px solid #d32f2f',
                        marginBottom: 3
                      }}>
                        <Typography variant="body2" color="error" sx={{ fontWeight: "700" }}>
                          {this.state.loginFailMsg}
                        </Typography>
                      </Box>}
    <Typography component="h1" sx={{ fontWeight:"700"}} style={styles.textFont}>
            Email Address
          </Typography>
          <FormControl error={this.state.error} fullWidth required>
        <Input
          type="email"
          disableUnderline
          sx={{
            backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
            border: '1px solid', borderColor: this.state.errorPassConfirm ? "red" : "#ccc"
          }}
          data-test-id="txtInputEmail"
          placeholder={"Email"}
          value={this.state.emailValue}
          onChange={(e) => this.setState({emailValue:e.target.value,error:false,loginFailMsg:""})}
          onBlur={this.handleBlur}
        />
        <FormHelperText sx={{ marginLeft: -0.2 }}>{this.state.error ? 'Please enter an email address.' : ''}</FormHelperText>
      </FormControl>
            
            </View>
            {/* <View style={styles.inputWrapper}> */}
              <Button onClick={()=>this.goTo()}
                data-test-id={"btnForgotPassword"}
                sx={{fontWeight:400,fontSize:"14px",textAlign:"left",justifyContent:"flex-start",textTransform:"none",color: "#1D4ED8",marginBottom:2,marginLeft:"15px"}}
              >Back to login</Button>
             
            {/* </View> */}
            <View
              style={styles.buttonsWrapper}
            >
              <Button sx={[styles.buttonsWrapper,{margin:"20px", textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px", fontWeight: "700",borderRadius:"8px",
                      height: "44px"  ,'&:hover': {
            backgroundColor: '#FFC300',  
            color: '#black',             
          },}]}
                data-test-id={"btnSendLink"}
                onClick={()=>this.sendEmail()}
              >Send Recovery Link</Button>
            </View>
            <View style={styles.inputWrapper}>
            <Typography sx={{justifySelf:"center",alignItems:"center",textAlign:"center"}} component="h1"  style={styles.textFont}>
              <span style={{fontWeight:400,fontSize:"14px"}}>If you didn't receive a link, </span>
             <span style={{color: "#1D4ED8",fontWeight:400,fontSize:"14px"}}> Resend</span>
          </Typography>
          
              </View>
          </View> :
          <View style={[styles.formWrapper,{ alignItems: 'center'}]}>
          
          <View style={styles.inputWrapper}>
          <Typography component="h6" sx={{fontSize:"24px",marginBottom:"15px",marginRight:3,fontWeight:"700",textAlign:"center"}} >
          Password Reset Requested
        </Typography>
        <img
              style={{height:"42px",width:"53px",alignItems:"center",alignSelf:"center",marginBottom:"20px"}}
              src={mail.default}
            />
        <Typography sx={{fontSize:"14px",textAlign:"center"}} style={styles.paraText}>
        We’ve sent a password reset link to {this.state.emailValue}. Please check your email and folow the instructions to reset your password.
        </Typography>
  
       
          
          </View>
          <View style={{padding: "5px"}}>
            <Button onClick={()=>this.sendEmail()}
              data-test-id={"btnResend"}
              sx={{textAlign:"left",justifyContent:"flex-start",textTransform:"none",color: "#1D4ED8",fontSize:"14px",fontWeight:400}}
            >Resend email</Button>
           
          </View>
          <View
            style={styles.buttonsWrapper}
          >
            <Button sx={[styles.buttonsWrapper,{margin:"20px", textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px", fontWeight: "700",borderRadius:"8px",
                   width:"327px",   height: "44px",marginBottom:"40px"  ,'&:hover': {
            backgroundColor: '#FFC300',  
            color: '#black',             
          },}]}
              data-test-id={"btnEmailLogIn2"}
              onClick={()=>this.goTo()}
            >Back to Log In</Button>
          </View>
         
        </View> }
          </Grid>
          </Grid>
        </View>
        </Grid>
     
      </Grid>
      </NavigationMenu>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  footerText: {
    textAlign: "center",
    fontWeight: "700",
    margin: 5
  },
  containerMobile: {
   
  },
  containerWeb: {
    
  },
  main: {
    display: "flex",
    alignItems: "center",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textFont: {
    fontFamily: "Helvetica",
    padding:5,
  },
  formWrapper: {
    display: "flex",
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    textTransform: 'none',
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center' ,
    justifyContent:"center",
    alignItems:"center",
    textAlignVertical:"center",
    alignContent:"center",
    
    
  },
  footerContent: {
    margin: '0 auto',
  },
  nav: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    marginBottom: '15px',
  },
  navLink: {
    color: 'white',
    margin: '5px 0',
  },
  navLinkHover: {
  },
  
  paraText:{
    fontFamily:"text-sm/font-normal",
  }
});
// Customizable Area End
