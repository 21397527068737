import React from "react";

// Customizable Area Start
import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  Text,
  TextInput,
  Image,
  KeyboardAvoidingView,
  TouchableOpacity,
} from "react-native";
import { Formik } from "formik";

import * as Yup from "yup";
import {
  FormControl,
  Grid,
  Input, FormHelperText,
  Button, InputAdornment, IconButton,
  Typography, Box
} from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { footer, header, logo } from "./assets";
import { deviceHeight } from "../../../framework/src/Utilities";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            Platform.OS === "web" ? styles.containerWeb : styles.containerMobile
          }
        >
          {/* ------------------- HEADER ---------------------- */}
          {/* Customizable Area Start */}
          <NavigationMenu navigation={undefined} id={""}>
          <View>
          <Grid container sx={{flexDirection:{md:"row-reverse"},backgroundColor:"#f3f3f1"}}>
          
        <Grid item xs={12} md={8}>
        <View style={styles.body}>
          <Grid container direction="column"  >
        
        <Grid item xs={8} >
       
          <View style={styles.formWrapper}>
          
            <View style={styles.inputWrapper}>
            <Typography component="h6" sx={{fontSize:"24px",marginBottom:"15px",fontWeight:"700"}} >
            New Password
          </Typography>
          {this.state.loginFailMsg.length > 0 &&
                      <Box sx={{
                        backgroundColor: '#ffe5e5',  color: '#d32f2f',
                        padding: '10px 16px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        borderLeft: '4px solid #d32f2f',
                        marginBottom: 3
                      }}>
                        <Typography variant="body2" color="error" sx={{ fontWeight: "700" }}>
                          {this.state.loginFailMsg}
                        </Typography>
                      </Box>}
          <Typography sx={{fontSize:"16px",fontWeight:700}} component="h1" style={styles.textFont}>
                      Enter New Password
                    </Typography>
                    <FormControl error={this.state.errorPass} fullWidth>
                      <Input
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid', borderColor: this.state.errorPass ? "red" : "#ccc"
                        }}
                        disableUnderline
                        data-test-id="txtInputPasswordnew"
                        type={this.state.enablePasswordField ? 'password' : 'text'}
                        placeholder={"Password"}
                        onBlur={() => this.handlePassblur()}
                        value={this.state.newPassword}
                        onChange={this.setPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-test-id="passwordShowHideIcon1"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? <VisibilityOffOutlinedIcon style={{ fill: "grey" }} /> : <VisibilityOutlinedIcon style={{ fill: "grey" }} />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText sx={{ marginLeft: -0.2 }}>{this.state.errorPass ? 'Please enter a new password.' : ''}</FormHelperText>
                    </FormControl>
                    <Typography sx={{fontSize:"16px",fontWeight:700,marginTop:2}} component="h1" style={styles.textFont}>
                      Confirm New Password
                    </Typography>
                    <FormControl  error={this.state.errorPassConfirm } fullWidth>
                      <Input
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid', borderColor: this.state.borderColor
                        }}
                        disableUnderline
                        data-test-id="txtInputPasswordConfirm"
                        type={this.state.btnConfirmPasswordShowHide ? 'password' : 'text'}
                        placeholder={"Password"}
                        onBlur={() => this.handlePassConfirmblur()}
                        value={this.state.confirmpassword}
                        onChange={this.setconfirm}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-test-id="togglePasswordButton"
                              onClick={this.handleClickShowPasswordConfirm}
                              edge="end"
                            >
                              {this.state.btnConfirmPasswordShowHide ? <VisibilityOffOutlinedIcon style={{ fill: "grey" }} /> : <VisibilityOutlinedIcon style={{ fill: "grey" }} />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText sx={{ marginLeft: -0.2 ,color:this.state.passMatched?"green":""}}>{this.state.errorPassConfirm || this.state.passMatched ? this.state.errorMsg : ''}</FormHelperText>
                    </FormControl>
            
            </View>
            
            <View
                    style={{
                      flex: 1,
                      padding: "20px"
                    }}
                  >
                    <Button sx={[{
                      textTransform: 'none',padding: '10px', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px", fontWeight: "700",borderRadius:"8px",
                      height: "44px",
                      '&:hover': {
                        backgroundColor: '#FFC300',
                      },
                    }]}
                    onClick={()=>this.confirm()}
                      data-test-id={"btnEmailLogInNew"}
                    >Create New Password</Button>
                  </View>
            <Button
                      data-test-id={"goToHomeButtonNew"}
                      sx={{
                        flex: 1, color: "#1D4ED8", marginBottom: 2,
                        marginLeft: 1,
                        textTransform: 'none'
                      }}
                      onClick={() =>this.goTo()}>
            <Typography  sx={{textAlign:"center", justifySelf:"center",alignItems:"center",color:"#1D4ED8",fontWeight:400,fontSize:"14px"}} component="h1"  style={styles.textFont}>
              Go to Login
          </Typography>
          </Button>
          </View> 
          </Grid>
          </Grid>
        </View>
        </Grid>
       
      </Grid>
           
          </View>
          </NavigationMenu>
          {/* Customizable Area End */}
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    
  },
  containerWeb: {
    
  },
  countryCodeSelector: {
   
  },
  button: {
   
  },

  flexContainer: {
   
  },

  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  webInput: {
    marginTop: 20,
    width: "100%",
    zIndex: -1,
  },

  inputAfterCountryCode: {
    width: "100%",
    zIndex: -1,
  },

  mobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
  },

  codeInput: {
    marginTop: 20,
    width: "30%",
  },

  phoneInput: {
    flex: 3,
    marginTop: 20,
  },

  noBorder: {
    borderBottomWidth: 0,
  },

  titleText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold",
  },

  stepText: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },

  emailText: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold",
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginTop: 20,
    minHeight: 40,
    fontSize: 18,
    textAlignVertical: "center",
    padding: 10,
  },

  bgRectWeb: {
    marginTop: 40,
  },

  errorStyle: {
    color: "red",
    textAlign: "center",
  },

  passwordShowHide: {
    alignSelf: "center",
  },

  passwordRulesStyle: {
    padding: 15,
  },

  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
  },

  passwordContainerWeb: {
    flexDirection: "row",
    borderBottomWidth: 1,
    backgroundColor: "#00000000",
    borderColor: "#767676",
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
  },
  passwordInputWeb: {
    flex: 1,
    fontSize: 18,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    borderColor: "#767676",
    borderRadius: 2,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center' ,
    justifyContent:"center",
    alignItems:"center",
    textAlignVertical:"center",
    alignContent:"center",
    
    
  },
  footerContent: {
    margin: '0 auto',
  },
  nav: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    marginBottom: '15px',
  },
  navLink: {
    color: 'white',
    margin: '5px 0',
  },
  navLinkHover: {
  },
  
  paraText:{
    fontFamily:"text-sm/font-normal",
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    textTransform: 'none',
  },
  main: {
    display: "flex",
    alignItems: "center",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textFont: {
    fontFamily: "Helvetica",
    padding:5,
  },
  formWrapper: {
    display: "flex",
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  footerText: {
    textAlign: "center",
    fontWeight: "700",
    margin: 5
  },
});
// Customizable Area End
