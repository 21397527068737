Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "rolesandpermissions2";
exports.labelBodyText = "rolesandpermissions2 Body";
exports.searchAPiEndPoint="bx_block_content_management/workers/search"
exports.editWorker="bx_block_content_management/workers/"
exports.addWorker="bx_block_content_management/workers"
exports.btnExampleTitle = "CLICK ME";
exports.methodapi = "GET";
exports.getOrgApiENdPoint="bx_block_content_management/organizations/show_organization"
exports.getAllServices="bx_block_content_management/service_offereds"
exports.updateOrgEndpoint="bx_block_content_management/organizations/update_organization"
// Customizable Area End