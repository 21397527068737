import React from "react";

// Customizable Area Start
import { View } from "react-native";
import {
  Grid,
  Typography, Box,
} from "@mui/material";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu";
import { leftIcon } from "./assets";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu navigation={this.props.navigation} id={""}>
        <Grid container>
          <Grid item xs={12}>
          <View style={{
              display: "flex",
              flexDirection: "column",
            }}>
              <Box sx={{ marginTop: "20px",padding: "0 25px" }}>
              <button style={styles.goBackBtn} onClick={()=>this.props.navigation.goBack()}><div><img src={leftIcon.default} style={{paddingRight:"5px",height:"8px"}}/></div> <div>Back</div></button>
                <Typography component="h1" sx={{  color:" #2d2a24", fontSize: "23px", fontWeight: "700", font: "Inter",marginBottom:"10px" }} >
                  Term of Use
                </Typography>
                {
                  this.state.listOfTermCondition.map(({ id, title, description }) => <Box key={id} >
                    <Typography component="h1" sx={{ fontSize: "24px", fontWeight: "700", font: "Inter", }} >
                      <div style={styles.heading} dangerouslySetInnerHTML={{ __html: title }}></div>
                    </Typography>
                    <Typography component="p" sx={{ textTransform: "inherit", }}>
                      <div style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: description }}></div>
                    </Typography>
                  </Box>)
                }
              </Box>
            
            </View>
          </Grid>
        </Grid>
      </NavigationMenu>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  heading: {
    color:" #2d2a24",
    lineHeight:"0",
    margin: 0, 
    "& p": {
      margin: "0 !important",
    },
  },
  goBackBtn:{
    color:"#1d4ed8",
    border:"none",
    background:"none",
    fontSize:"14px",
    marginBottom:"20px",
    display: "flex",
    lineHeight: "10px",
    gap:"10px",
  }
}
// Customizable Area End
