// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start

  Box,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogContent,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TableContainer,
  Paper,
  ListItemAvatar,
  Avatar,
  IconButton,
  DialogTitle,
  DialogActions,
  OutlinedInput,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import * as Yup from "yup";
import  { ValueType } from "react-select";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import Pagination from '@mui/material/Pagination';
import { styled } from "@mui/styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";
import {footer, header, logo} from "../../forgot-password/src/assets"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { View } from "react-native";
import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data,
} from "./CustomFormController.web";

const TextContainer = styled(Box)({
  height:"auto",
  backgroundColor: '#f0f0f0',
  padding:"16px",
});
const StyledTableCell = styled(TableCell)({
  fontSize: '14px',
  fontWeight: 700,
  borderBottom: '1px solid #CBD5E1', 
  padding: '16px', 
  color:"#2D2A24"
});

const StyledTableRow = styled(TableRow)({
  '&:last-child td': {
    borderBottom: 'none',
  },
});
const FooterText = styled(Typography)({
  textAlign: "center",
  fontWeight: "700",
  margin: 5,
  fontSize: "14px"
});

const webStyles = {
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  goBackButton: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'none',
    gap:"8px",
  },
  goBackText: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#1D4ED8',
    lineHeight: '22px',
  },
  comunityType: {
    color:"#2D2A24",
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing:"-0.05%",
    marginTop:"16px"
  },
  areaText: {
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    margin:"32px 0 8px 0"
  },
  inputDefaultLabel: {
    color:"#94A3B8",
    fontSize:"16px",
    fontWeight:400
  },
  formControl: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height:"44px",
  },
  menuItems: {
    backgroundColor: '#ffffff',
    borderBottom: "1px solid #CBD5E1",
    color:"#2D2A24",
    fontSize:"14px",
    fontWeight:700,
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  menuItemsSecond: {
    fontWeight:700,
    backgroundColor: '#ffffff',
    fontSize:"14px",
    color:"#2D2A24",
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  mapButton: {
    height:"44px",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    textTransform:"capitalize",
    borderRadious:"8px",
    marginTop:"32px",
  },
  submitButton: {
    marginTop:"24px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
  },
  listItemName: {
    fontWeight:700,
    fontSize:"14px",
    color:"#2D2A24",
  },
  listItemQuantity: {
    fontWeight:400,
    fontSize:"16px",
    color:"#2D2A24",
  },
  itemsMainBox: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%",
  },
  checkboxContainer: {
    justifyContent:"space-between",
    display:"flex",
    alignItems:"center",
  },
  selectMenu: {
    height: "44px",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
  },
  estimatedItems: {
    color:"#2D2A24",
    fontWeight:400,
    fontSize:"16px",
  },
  estimatedBox: {
    height:"44px",
    justifyContent:"space-between",
    alignItems:"center",
    display:"flex",
    marginTop:"8px",
    padding:"0px 32px"
  },
  totalItems: {
    fontWeight:700,
    fontSize:"16px",
    color:"#2D2A24",
  },
  uploadButtonBox: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  photoCamera: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  cameraIcon: {
    color: "#2D2A24",
    fontSize: "24px"
  },
  addPictureText: {
    marginLeft: '16px',
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:400
  },
  pendingImageBox: {
    height: "48px",
    width: "48px",
    borderRadius: "6px",
    backgroundColor: '#FFFDC1'
  },
  defaultImageIcon:{
    color:"#F5C502",
    fontSize: "20px"
  },
  uploadButton: {
    backgroundColor: '#F5C502',
    height:"44px",
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:700,
    textTransform:"capitalize",
  },
  checkBoxMenu: {
    height: "44px",
  },
  serviceSelect: {
    height:"44px",
    fontSize:"16px",
    fontWeight:700,
  },
  umbrellaImageBox: {
    backgroundColor: "white",
    display: 'flex',
    width: "100%"
  },
  umbrellaImage: {
    width: "375px",
    height: "240px",
    padding: "20px",
    alignSelf: "center"
  },
  addPictureHeadingText: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#2D2A24"
  },
  tableContainerBox: {
    boxShadow: 'none',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
  },
  addPictureIcon: {
    position: 'absolute',
    right: "4.51px",
    top: "4.51px"
  },
  footerGridBox: {
    height: "auto",
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    justifyContent: "center",
    alignItems: "center",
    textAlignVertical: "center",
    alignContent: "center"
  },
  footerLogo: {
    width: "190.02px",
    height: "26.47px",
    backgroundColor: "#F5C502",
    borderRadius: 20,
    marginLeft: 10, 
    margin: 5,
    paddingLeft: 1, 
    marginBottom: 5
  },
  footerView: {
    height: 1, 
    backgroundColor: "#F5C502", 
    paddingHorizontal: 20
  }
};
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required("*First name is required!"),
      lastName: Yup.string().required("*Last name is required!"),
      phoneNumber: Yup.string().required("*Phone number is required!"),
      email: Yup.string().required("*Email is required!"),
      organization: Yup.string().required("*Organization is required!"),
      teamName: Yup.string().required("*Team name is required!"),
      userType: Yup.string().required("*User type is required!"),
      rating: Yup.string().required("*Rating type is required!"),
      gender: Yup.string().required("*Gender is required!"),
      address: Yup.string().required("*Address is required!"),
      country: Yup.string().required("*Country is required!"),
      state: Yup.string().required("*State is required!"),
      city: Yup.string().required("*City is required!"),
      file: Yup.string().required("*File is required!").nullable(),
    });
  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  getValue = (event: ValueType<Dropdown, false>) => {
    return event ? event.value : "";
  };

  renderRequestUmbrela = () => {
    return (
      <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
        <Grid container sx={{ width: '100%', backgroundColor: "#F5C502", padding: 1 }}>
          <Grid item xs={11}>
            <img
              style={webStyles.headerLogo}
              src={header.default}
            />
          </Grid>
          <Grid item sx={{ alignItems: "flex-end" }} xs={1}>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <Grid container direction="column">
              <Grid item xs={4} sx={webStyles.umbrellaImageBox} justifyContent={'center'} alignItems={'center'}  >
                <img
                  src={logo.default}
                  alt="SVG Icon"
                  style={webStyles.umbrellaImage}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    )
  }
  renderFileList = () => {
    return (
      <List>
        {this.state.selectedFiles.map((file, index) => (
          <ListItem key={index} divider sx={webStyles.uploadButtonBox}>
            <ListItemAvatar>
              <Avatar style={webStyles.pendingImageBox}>
                <ImageOutlinedIcon style={webStyles.defaultImageIcon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={file.name}
              secondary={`${(file.size / 1024).toFixed(2)} KB`}
            />
            <IconButton edge="end">
              <MoreVertOutlinedIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.renderRequestUmbrela()}
        <TextContainer>
          <Button sx={webStyles.goBackButton}>
            <KeyboardArrowLeftIcon />
            <Typography style={webStyles.goBackText}>Go Back</Typography>
          </Button>
          <Typography style={webStyles.comunityType}>Community Safety and Cleanliness</Typography>
          <Typography style={webStyles.areaText}>Area</Typography>
          <FormControl variant="outlined" fullWidth
            sx={webStyles.formControl}>
            <InputLabel style={webStyles.inputDefaultLabel}>Select Area Serviced</InputLabel>
            <Select
              data-test-id="serviceTestId"
              value={this.state.selectedService}
              onChange={this.handleChange}
              label="Select Area Serviced"
              inputProps={<OutlinedInput notched={false} />}
              style={webStyles.serviceSelect}
            >
              <MenuItem value="serviceCompleted" style={webStyles.menuItems}>Service Completed</MenuItem>
              <MenuItem value="requestService" style={webStyles.menuItemsSecond}>Request Service</MenuItem>
            </Select>
          </FormControl>
          <Button fullWidth sx={webStyles.mapButton}>Map</Button>
          <Box>
            <Typography variant="h6" style={webStyles.areaText}>{this.state.labelTextFirst}</Typography>
            <FormControl fullWidth variant="outlined"
              sx={webStyles.formControl}>
              <Select
                multiple
                value={this.state.selectedItems}
                onChange={this.handleSelectChange}
                displayEmpty
                renderValue={(selected) =>
                  selected.length ? selected.join(', ') : 'Select items'
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                style={webStyles.selectMenu}
              >
                {this.state.items.map((item, index) => (
                  <MenuItem key={item.name} value={item.name}
                  style={{
                    ...webStyles.checkBoxMenu,
                    borderBottom: index === this.state.items.length - 1 ? 'none' : '1px solid #CBD5E1',
                  }}
                >
                    <Box data-test-id="checkBoxId" sx={webStyles.itemsMainBox} key={item.name} onClick={() => this.handleToggle(index)}>
                      <Box sx={webStyles.checkboxContainer}>
                        <Checkbox
                          edge="start"
                          checked={item.checked}
                          tabIndex={-1}
                          disableRipple
                          defaultChecked
                          sx={{
                            color: "#64748B",
                            borderRadius: "6px",
                            '&.Mui-checked': {
                              color: "#CF9500",
                            },
                          }}
                        />
                        <Typography style={webStyles.listItemName}>{item.name}</Typography>
                      </Box>
                      <Typography style={webStyles.listItemQuantity}>{item.quantity}</Typography>

                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="h6" style={webStyles.areaText}>
              {this.state.labelTextSecond}
            </Typography>
            <TableContainer component={Paper} style={webStyles.tableContainerBox}>
              <Table>
                <TableBody>
                  {this.state.items
                    .filter((item) => item.checked)
                    .map((item) => (
                      <StyledTableRow key={item.name}>
                        <StyledTableCell style={{ background: "#F1F5F9" }}>{item.name}</StyledTableCell>
                        <StyledTableCell align="right" style={{ fontSize: "16px", fontWeight: 400 }}>{item.quantity}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={webStyles.estimatedBox}>
              <Typography variant="h6" style={webStyles.estimatedItems}>
                {this.state.NumberOfItems}
              </Typography>
              <Typography style={webStyles.totalItems}> {this.state.estimatedTotal}</Typography>
            </Box>
            <Button data-test-id="openModalTestId" fullWidth sx={webStyles.mapButton} onClick={this.handleOpen}>Add Picture</Button>
            <Button fullWidth sx={webStyles.submitButton}>Submit</Button>
          </Box>

          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="add-picture-dialog"
          >
            <DialogTitle>
              <span style={webStyles.addPictureHeadingText}>Add Picture</span>
              <IconButton
                data-test-id="closeModalTestId"
                aria-label="close"
                onClick={this.handleClose}
                sx={webStyles.addPictureIcon}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderFileList()}
              <label>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={webStyles.uploadButtonBox}
                  style={{ cursor: 'pointer' }}
                >
                  <Avatar style={webStyles.photoCamera}>
                    <CameraAltOutlinedIcon style={webStyles.cameraIcon} />
                  </Avatar>
                  <Typography variant="body1" style={webStyles.addPictureText}>
                    Add picture
                  </Typography>
                </Box>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={this.handleFileChange}
                />
              </label>

            </DialogContent>
            {this.state.selectedFiles.length > 0 && (
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={this.handleClose}
                  sx={webStyles.uploadButton}
                >
                  Upload
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </TextContainer>
        <Grid sx={webStyles.footerGridBox} item xs={12} md={4}>
          <footer >
            <div style={{ margin: '0 auto' }}>
              <img
                style={webStyles.footerLogo}
                src={footer.default}
              />
              <div style={{ margin: 10, paddingTop: 5 }}>
                <FooterText>Who We Are</FooterText>
                <View style={webStyles.footerView} />
              </div>
              <div style={{ margin: 10 }}>
                <FooterText>Help Center</FooterText>
                <View style={webStyles.footerView} />
              </div>
              <div style={{ margin: 10 }}>
                <FooterText>Why We Do It</FooterText>
                <View style={webStyles.footerView} />
              </div>
              <div style={{ margin: 10 }}>
                <FooterText>What We Do It</FooterText>
                <View style={webStyles.footerView} />
              </div>
              <div style={{ margin: 10 }}>
                <FooterText>Contact Us</FooterText>
                <View style={webStyles.footerView} />
              </div>
              <div >
                <Typography style={{ textAlign: "center", fontWeight: "400", fontSize: "16px", margin: 5 }}>Copyright © All right reserved.</Typography>
                <Typography style={{ fontWeight: "400", fontSize: "14px", margin: 5 }}>
                  <a style={{ color: "#60A5FA" }}  >Privacy Policy -</a>
                  <a style={{ color: "#60A5FA" }}  >Terms of Use</a>
                </Typography>
              </div>
            </div>
          </footer>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
