import React from "react";
// Customizable Area Start
import {
  Grid,
  Typography, Box,Button,FormControl,Radio,FormControlLabel,RadioGroup,InputLabel,Select,MenuItem,Divider
} from "@mui/material";

import { styled } from '@mui/material/styles';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {View, Dimensions, Platform } from "react-native";
import { filterIcon,searchIcon,leftIcon} from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu";
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#cf9500',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#cf9500',
  },
});
const DatePickerComp = styled('span')(({ theme }) => ({
 '.react-datepicker-wrapper ' :{
  width:"100%"
 },
 ".react-datepicker__input-container" :{
   width:"100%"
 },
 ".react-datepicker__input-container input" :{
  width:"100%",
  padding:"15px",
  borderRadius:"5px",
  outline:"none",
  borderColor: " #fff787",
  borderWidth:"1px",
  borderStyle:"solid"
},
".react-datepicker__day--selected:not([aria-disabled='true']):hover":{
  background: "#fffde7",
  color:"#000000"
},
".react-datepicker__header ":{
  textAlign: "center",
  backgroundColor: "#fff",
  borderBottom: "none",
  padding:" 17px 0 0 0",
  position: "relative",
},
".react-datepicker__day--selected":{
  background: "#fffde7",
  color:"#000000",
  borderColor: " #fff787",
  borderWidth:"1px",
  borderStyle:"solid"
}

}));


// Inspired by blueprintjs
function BpRadio(props:any) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isToggled,selectedFilter } = this.state;
    return (
      <div>
        <NavigationMenu navigation={this.props.navigation} id={""} >
        <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
          <Grid item xs={12} md={8}>
            <View style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
              <Grid container direction="column">
                <Grid item xs={8} style={{ padding: 5 }}>
                  <View style={{
                    display: "flex",
                    paddingTop: "20px", padding: 10
                  }}>
                     <Typography component="h1" style={{ fontSize: "14px" }} >
                      <span style={{ color: "#1D4ED8" }}> <img src={leftIcon.default} alt="arow icon" width={"16px"} height={"16px"} style={{transform: "translateY(3px)"}}/> Go Back</span>
                    </Typography>
                    <Typography sx={{
                      flex: 1,
                      padding: "10px", fontWeight: "700",fontSize:"24px",font:"Inter"
                    }}>
                      Maps
                    </Typography>
                    <Typography sx={{
                      flex: 1,
                      padding: "10px", fontWeight: "700",fontSize:"17px",font:"Inter"
                    }}>
                     Outreach Workers Performance
                    </Typography>
                  </View>
                </Grid>
                <Grid item xs={8} style={{ padding: 5 }}>
                  <View style={{
                    display: "flex",
                    paddingTop: "20px", padding: 10
                  }}>
                    <Box sx={{background:"#fff", borderRadius:"10px",padding:"10px 20px"}}>
                        <Box sx={{display:"flex", alignItems:"center",justifyContent:"space-between"}}> 
                          <Typography sx={{
                          
                            flex: 1,
                            fontWeight: "700",fontSize:"16px",font:"Inter"
                          }}>
                          Filter:
                        </Typography>
                        <div>
                          <Button onClick={this.handleToggle} sx={{border:"1px solid #CBD5E1",
                           height:"45px",
                            minWidth:'45px',
                            borderRadius:"8px",
                            boxShadow: '0px 2px 8px 0px #00000014',
                          }}>
                            <img src={filterIcon.default} alt="Filter Icon"/>
                          </Button>
                        </div>
                     </Box>
                     {isToggled && 
                     <div>
                      <div style={styles.filterContainer}>
                        <FormControl component="fieldset" style={{width:"100%",display:"block"}}>
                          <RadioGroup
                            value={selectedFilter}
                            onChange={this.handleFilterChange}
                            // className={classes.radioGroup}
                          >
                            <ul style={{margin:0,padding:0,listStyle:"none"}}>
                           {["All", "Team Member", "Service Type", "Date Range"].map((label) => (
                             <li style={{borderBottom:"1px solid #ddd",padding:"5px"}}> <FormControlLabel
                             key={label}
                             value={label}
                             control={<BpRadio />}
                             label={label}
                           /></li>
                            ))}
                            </ul>
                          </RadioGroup>
                        </FormControl>
                        </div>
                          {/* Conditional rendering for Service Type */}
                          {selectedFilter === 'Service Type' && (
                            // <FormControl fullWidth>
                            //   <InputLabel>Select a Service</InputLabel>
                            //   <Select
                            //     // value={selectedService}
                            //     // onChange={this.handleServiceChange}
                            //     // className={classes.select}
                            //   >
                            //     <MenuItem value="Service 1">Service 1</MenuItem>
                            //     <MenuItem value="Service 2">Service 2</MenuItem>
                            //     <MenuItem value="Service 3">Service 3</MenuItem>
                            //   </Select>
                            // </FormControl>
                            <FormControl variant="outlined" fullWidth
                              sx={styles.formControl} style={{border:"1px solid #CBD5E1"}}>
                              <InputLabel style={styles.inputDefaultLabel}>Select a Service</InputLabel>
                              <Select
                                labelId="service-select-label"
                                id="service-select"
                                data-test-id="serviceTestId"
                                // value={this.state.selectedService}
                                // onChange={this.handleChange}
                                // label="Select a Service"
                                // inputProps={{
                                //   name: 'service',
                                //   id: 'service-select',
                                // }}
                                style={{height:"44px"}}
                              >
                                <MenuItem value="Service 1" style={styles.menuItems}>Service 1</MenuItem>
                                <MenuItem value="Service 2" style={styles.menuItems}>Service 2</MenuItem>
                              </Select>
                              </FormControl>
                          )}
                          {selectedFilter === "Date Range" && (
                          <div style={{marginTop:"20px"}}>
                            <DatePickerComp>
                            <DatePicker
                              selected={this.state.date} onChange={this.handleDateChange}
                            />
                            </DatePickerComp>
                          </div>)}
                      
                      </div>
                      }
                    </Box>
                    <Divider sx={{my:1}}/>
                      <Box sx={{position:"relative"}}>
                        <img style={{position:"absolute",top:"10px",left:"6px"}} src={searchIcon.default} alt="search icon"/>
                        <input type="text" placeholder="Search on map" style={{width:"100%",borderRadius:"8px",outline:"none",border:"1px solid #ddd",padding:"14px 0 12px 38px",fontSize:"14px"}}/>
                      </Box>
                    </View>
                  </Grid>
                <Grid item xs={8} style={{ padding: 5 }}>
                  <View>
                    <MapView
                      style={styles.map}
                      initialRegion={{
                        latitude: 37.78825,
                        longitude: -122.4324,
                        latitudeDelta: 0.0922,
                        longitudeDelta: 0.0421,
                      }}
                    />
                  </View>
                </Grid>
              </Grid>
            </View>
            </Grid>
        </Grid>
        </NavigationMenu>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  map: {
    // width: viewportWidth,
    // height: viewportHeight,
    width:"100%",
    height:"400px",
    // position: "absolute",
    // top: 0,
    // bottom: 0,
  },
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica",
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px"
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent"
  },
  inputDefaultLabel: {
    color: "#94A3B8",
    fontSize: 14,
    fontWeight: "400"
  },
  formControl: {
    backgroundColor: '#ffffff',
    height:"44px",
    marginTop:"20px"
  },
  menuItems: {
    backgroundColor: '#ffffff',
    color: "#2D2A24",
    fontSize: 14,
    fontWeight: "700",
    '&:hover': {
      background: '#FFFDE7',
    },
  },
  menuItemsSecond: {
    fontWeight: "700",
    backgroundColor: '#ffffff',
    fontSize: 14,
    color: "#2D2A24",
    '&:hover': {
      background: '#FFFDE7',
    },
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  footerText: {
    textAlign: "center",
    margin: 5
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  filterContainer: {
    border:"1px solid #CBD5E1",
    borderRadius:8,
    marginTop:"5px"
  },
  datePicker: {
    width: "100%",
  },
 
};
// Customizable Area End
