import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import PersonIcon from '@mui/icons-material/Person';
import { footer, mainLogo, mainHeroBanner,login,logout, userProfile } from "./assets";

import { View } from "react-native";
import { deviceHeight } from "../../../framework/src/Utilities";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  list = () => (
    <div
      className="left"
      style={{
        width: 250,
      }}
      role="presentation"
      onClick={this.toggleDrawer}
      onKeyDown={this.toggleDrawer}
    >
      <Box style={webStyle.userProfileWrapper}>
        <img src={userProfile} style={webStyle.userProfile} />
        <Typography variant="h6">{configJSON.userName}</Typography>
        <Typography variant="h6" style={webStyle.userDesignation}>
          {configJSON.userDesignation}
        </Typography>
        <Button
          onClick={this.toggleDrawer}
          variant="text"
          style={webStyle.logout}
        >
          {configJSON.logout}
        </Button>
      </Box>
      <Divider />

    </div>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container sx={{ backgroundColor: "#F5C502", padding: "10px 22px" }}>
            <Grid container sx={{ backgroundColor: "#F5C502" }}>
              <Grid item xs={8}>
                <img
                  style={webStyle.headerLogo}
                  src={mainLogo.default}
                />
              </Grid>
              <Grid item sx={{ alignItems: "flex-end",justifyContent:"flex-end",display:"flex" }} xs={4}>
                {this.state.isLoggedIn ? 
                  <Button  data-test-id="btngotoEmailLogIn" onClick={() => this.gotoPage('EmailAccountLoginBlock')}> 
                   <Box sx={{display:"flex", alignItems: "center",gap:"6px",justifyContent:"center" }}>
                   <img src={logout.default} alt="Logout icon"/>
                    <Typography sx={{ fontWeight:"700",transform: "translateY(2px)", color: "#000",fontSize: "12px", font: "inter", textTransform: "capitalize" }}>
                      Logout
                    </Typography>
                   </Box>
                  </Button> 
                  : 
                  <Button>
                    <Box sx={{ display: "flex", alignItems: "center",gap:"6px",justifyContent:"center" }}>
                    <img src={login.default} alt="Login icon"/>
                    <Typography sx={{ fontWeight: "700",transform: "translateY(2px)",color: "#000", fontSize: "12px", font: "inter", textTransform: "capitalize" }}>
                      Login
                    </Typography>
                    </Box>
                  </Button>
                }
              </Grid>
            </Grid>
  
          </Container>
          <Grid item xs={4} sx={{ backgroundColor: "white", display: 'flex', width: "100%" }} justifyContent={'center'} alignItems={'center'}  >
            <img
              src={mainHeroBanner.default}
              alt="SVG Icon"
              style={{
                width: "100%",
                height: "240px",
                alignSelf: "center"
              }}
            />
          </Grid>
          {this.props.children}
          <Grid sx={{
            height: { md: deviceHeight, l: deviceHeight }, backgroundColor: '#333',
            color: 'white',
            padding: '20px',
            textAlign: 'center',
            justifyContent: "center",
            alignItems: "center",
            textAlignVertical: "center",
            alignContent: "center"
          }} item >
            <footer >
              <div style={{ margin: '0 auto' }}>
                <img
                  style={{
                    width: "185.02px",
                    height: "26.47px",
                    backgroundColor: "red",
                    borderRadius: 20,
                    marginBottom: 5
                  }}
                  src={footer.default}
                />

                <div style={{ margin: 2, paddingTop: 5 }}>

                  <Typography data-test-id="WhoweareBtn" onClick={() => this.handleClick("https://bronxopioidcollective.org/our-partners/")}
                    sx={[{ fontSize: "14px" }, webStyle.footerText]} >
                    Who We Are</Typography>
                  <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 20 }} />
                </div>
                <div style={{ margin: 1 }}>
                  <Typography sx={[{ fontSize: "14px" }, webStyle.footerText]} >Help Center</Typography>
                  <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
                </div>
                <div style={{ margin: 10 }}>
                  <Typography data-test-id="WhywedoBtn" sx={[{ fontSize: "14px" }, webStyle.footerText]} onClick={() => this.handleClick("https://bronxopioidcollective.org/our-work/")}>Why We Do It</Typography>
                  <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
                </div>
                <div style={{ margin: 10 }}>
                  <Typography data-test-id="HowwedoBtn" onClick={() => this.handleClick("https://bronxopioidcollective.org/outreach/")}
                    sx={[{ fontSize: "14px" }, webStyle.footerText]}>How We Do It</Typography>
                  <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
                </div>
                <div style={{ margin: 10 }}>
                  <Typography data-test-id="helpBtn" onClick={() => this.goto()} sx={[{ fontSize: "14px" }, webStyle.footerText]}>Contact Us</Typography>
                  <View style={{ height: 1, backgroundColor: "#F5C502", paddingHorizontal: 10 }} />
                </div>
                <div >
                  <Typography style={{ textAlign: "center", fontWeight: 400, fontSize: "16px", margin: 5 }}>Copyright © All right reserved.</Typography>
                  <Typography style={{ fontWeight: 400, fontSize: "14px", margin: 5 }}>
                    <span data-test-id="privacyBtn" style={{ color: "#60A5FA",cursor:"pointer" }} onClick={()=>this.props.navigation.navigate("TermsConditionsDetail")}  >Privacy Policy -</span>
                    <span data-test-id="TermsBtn" style={{ color: "#60A5FA",cursor:"pointer" }} onClick={()=>this.props.navigation.navigate("TermsConditions")} >Terms of Use</span>
                  </Typography>
                </div>
              </div>
            </footer>
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    // paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
  headerLogo: {
    height: "28px",
    backgroundColor: "#F5C502",   
    marginRight: 10
  },
  footerText: {
    textAlign: "center",
    fontWeight: "700",
    margin: 1
  },
};
// Customizable Area End
