export const triangle = require("../assets/triangle.png");
export const progressBar = require("../assets/Circle.png");
export const settingIcon = require("../assets/settings.png");
export const progressIcon = require("../assets/Progress.png");
export const mailIcon = require("../assets/mail.png");
export const qrCodeIcon = require("../assets/qrcode.png");
export const payPalLogo = require("../assets/paypal.png");
export const rightArrow = require("../assets/Icon.png");
export const header =  require("../assets/header.png")
export const footer =  require("../assets/footer.png")
export const logo =  require("../assets/LOGO.svg")
export const mailLogo =  require("../assets/mail.svg")