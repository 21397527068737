import React from "react";

// Customizable Area Start
import { View } from "react-native";
import {
  Grid,
  Typography, Box,
} from "@mui/material";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { leftIcon } from "./assets";
// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return(
      <div>
        
        <NavigationMenu navigation={this.props.navigation} id={""}>
          <Grid container>
            <Grid item xs={12}>
              <View style={{
                display: "flex",
                flexDirection: "column",
              }}>
                <Box sx={{ marginTop: "20px", padding: "0 25px" }}>
                  <button  data-test-id={"goBackButton"} style={styles.goBackBtn} onClick={()=>this.props.navigation.goBack()}><div><img src={leftIcon.default} style={{paddingRight:"5px",height:"8px"}}/></div>
                   <div>Back</div></button>
                  <Typography component="h1" sx={{ color: " #2d2a24", fontSize: "24px", fontWeight: "700", font: "Inter", marginBottom: "10px" }} >
                    Privacy Policy
                  </Typography>
                  {
                  this.state.listOfTermCondition.map(({ id, title, description }) => <Box key={id} >
                    <Typography 
                    component="h1" 
                    sx={{ fontSize: "24px", fontWeight: "700", font: "Inter", }} >
                      <div 
                      style={styles.heading} dangerouslySetInnerHTML={{ __html: title }}></div>
                    </Typography>
                    <Typography 
                    component="p" 
                    sx={{ textTransform: "inherit", }}>
                      <div style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: description }}></div>
                    </Typography>
                  </Box>)
                }
                </Box>
              </View>
            </Grid>
          </Grid>
        </NavigationMenu>
      </div>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  heading: {
    color:" #2d2a24",
    lineHeight:"0",
    margin: 0, 
    "& p": {
      margin: "0 !important",
    },
  },
  goBackBtn:{
    display: "flex",
    color:"#1d4ed8",
    background:"none",
    fontSize:"14px",
    border:"none",
    marginBottom:"20px",
    lineHeight: "10px",
    gap:"8px",
  }
}
// Customizable Area End
