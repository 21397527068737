import React from "react";
// Customizable Area Start
import {
  Box,
  Container,
  styled,
  Typography,
  Button,
  Checkbox,
  TextField,
  Paper,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton,
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// Customizable Area End

import AdministratedServicesController, { Props } from "./AdministratedServicesController.web";



export default class AdministratedServices extends AdministratedServicesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  renderFormForDrugTestingMachine = () => (
    <Box>
      <Paper sx={{ marginTop: 2 }}>
        {this.state.nestedCheckboxes.map((nestedCheckbox, index) => (
          <Box
            data-test-id="checkBoxId"
            sx={webStyle.subCheckBoxContainer}
          >
            <Checkbox
              edge="start"
              tabIndex={-1}
              onChange={() => this.handleNestedCheckboxToggle(index)}
              disableRipple
              sx={webStyle.subCkeckBox}
            />
            <Typography style={webStyle.subMenulistItemName}>{nestedCheckbox.name}</Typography>
          </Box>
        ))}
      </Paper>
      {this.state.nestedCheckboxes[0].checked && (
        <Box mt={2}>
          <Typography variant="body2" sx={webStyle.lableText}>Location (Point of Purchase)</Typography>
          <TextField fullWidth variant="outlined" size="small" sx={webStyle.subBoxinputField} />

          <Typography variant="body2" sx={webStyle.lableText}>Bag Name</Typography>
          <TextField fullWidth variant="outlined" size="small" sx={webStyle.subBoxinputField} />

          <Typography variant="body2" sx={webStyle.lableText}>Substance</Typography>
          <TextField fullWidth variant="outlined" size="small" sx={webStyle.subBoxinputField} />

          <Typography variant="body2" sx={webStyle.lableText}>Drug Test Results</Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={3}
            placeholder="Add additional information"
            sx={webStyle.subBoxinputField}
          />
        </Box>
      )}
    </Box>
  );

  renderModal = () => (
    <Dialog
      open={this.state.isModalOpen}
      onClose={this.handleCloseModal}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Typography sx={webStyle.modalHeading}>
          Performed and Facilitated Care
        </Typography>
        <IconButton
          onClick={this.handleCloseModal}
          sx={{ position: 'absolute', right: 8, top: 2 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', paddingY: 4 }}>
        <CheckCircleOutlineIcon
          sx={{
            fontSize: '64px',
            color: '#059669',
            marginBottom: 2,
          }}
        />
        <Typography variant="h6" sx={webStyle.arrowIcon}>
          Form Submitted
        </Typography>
      </DialogContent>
    </Dialog>
  )

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={undefined} id={""}>
        <Container maxWidth="md">
          <Box sx={webStyle.productContainer}>
            <GoBackBox>
              <ArrowBackIosIcon style={webStyle.arrowIcon} />
              <Typography style={webStyle.goBackText}>Go Back</Typography>
            </GoBackBox>
            <Typography style={webStyle.headingText}>Performed and Facilitated Care</Typography>
            <MapButton>Map</MapButton>
            <Box sx={webStyle.checkBoxContainer}>
              {this.state.item.map((item, index) => (
                <Box
                  data-test-id="checkBoxId"
                  key={index}
                >
                  <Box sx={webStyle.checkboxContainer}>
                    <Checkbox
                      data-test-id="checkBoxCallID"
                      edge="start"
                      checked={item.checked}
                      onChange={() => this.handleToggle(index)}
                      tabIndex={-1}
                      disableRipple
                      sx={webStyle.bigCheckBox}
                    />
                    <Typography style={webStyle.listItemName}>{item.name}</Typography>
                  </Box>

                  {item.id === 1 && item.checked && (
                    <TextField
                    variant="outlined"
                    size="small"
                    value={item.inputValue}
                    onChange={(event) => this.handleInputChange(index, event)}
                    placeholder="Enter Narcan"
                    fullWidth
                    sx={webStyle.inputFieldDetails}
                  />
                  
                  )}
                  {item.id === 6 && item.checked && this.renderFormForDrugTestingMachine()}
                </Box>
              ))}
            </Box>
            <Button sx={webStyle.buttonCollection}
              data-test-id="addPictureId"
              onClick={this.handleOpenImageModal}
            >Add Picture
            </Button>
            <Button sx={webStyle.buttonCollection}
              data-test-id="successId"
              onClick={this.handleOpenModal}
            >Submit
            </Button>
            {this.renderModal()}
          </Box>
        </Container>
      </NavigationMenu>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const GoBackBox = styled(Box)({
  height: "22px",
  width: "78px",
  display:"flex",
  gap:"8px",
  alignItems:"center",
});

const MapButton = styled(Button)({
  height: "44px",
  width: "100%",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  borderRadius:"8px",
  background:"#F5C502",
  color:"#2D2A24",
  fontSize:"16px",
  fontWeight:700,
  textTransform:"capitalize",
  cursor:"pointer",
  marginTop:"32px",
});

const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    margin: "20px 0px",
  },
  goBackText: {
    color:"#1D4ED8",
    fontSize:"14px",
    fontWeight:400,
  },
  arrowIcon: {
    fontSize:"14px",
    fontWeight:400,
    color:"#2D2A24"
  },
  headingText: {
    color:"#2D2A24",
    fontSize:"24px",
    fontWeight:700,
    marginTop:"16px",
  },
  buttonCollection: {
    marginTop:"24px",
    borderRadius:"8px",
    display:"flex",
    height: "44px",
    width: "100%",
    fontSize:"16px",
    alignItems:"center",
    background:"#F5C502",
    justifyContent:"center",
    color:"#2D2A24",
    cursor:"pointer",
    fontWeight:700,
    textTransform:"capitalize",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center", 
  },
  listItemName: {
    fontWeight:400,
    color:"#2D2A24",
    fontSize:"16px",
  },
  subMenulistItemName: {
    fontSize:"14px",
    fontWeight:700,
    color:"#2D2A24",
  },
  checkBoxContainer: {
    margin: "24px 0px 16px 0px",
    width:"100%",
    maxWidth: "100%",
  },
  inputFieldDetails: {
    width:"100%",
    border: "1px solid #CBD5E1",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "8px",
    '& .MuiOutlinedInput-root': {
      borderRadius: "8px",
      '& fieldset': {
        borderColor: "transparent",
      },
      '&:hover fieldset': {
        borderColor: "transparent",
      },
      '&.Mui-focused fieldset': {
        borderColor: "transparent",
      },
    }
  },
  subCheckBoxContainer: {
    display:"flex",
    alignItems:"center",
    borderBottom:"1px solid #CBD5E1",
    height:"44px",
  },
  subCkeckBox: {
    color: "#64748B",
    borderRadius: "6px",
    paddingLeft:"32px",
    '&.Mui-checked': {
      color: "#CF9500",
    },
    '& .MuiSvgIcon-root': {
      fontSize: "16px",
    },
  },
  lableText: {
    fontWeight: 700,
    fontSize:"16px",
    color:"#2D2A24",
    marginBottom:"6px",
  },
  subBoxinputField: {
    marginBottom:"12px",
    fontSize: "16px",
    width:"100%",
    color: "#94A3B8",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    fontWeight: 400,
    '& .MuiOutlinedInput-root': {
      borderRadius: "8px",
      '& fieldset': {
        borderColor: "transparent",
      },
      '&.Mui-focused fieldset': {
        borderColor: "transparent",
      },
      '&:hover fieldset': {
        borderColor: "transparent",
      },
    }
  },
  modalHeading: {
    width:"80%",
    fontWeight: 700,
    color:"#2D2A24",
    fontSize:"24px", 
  },
  uploadImageBox: {
    cursor: "pointer",
    gap: "16px",
    boxShadow: "0px 2px 4px 0px #00000026",
    padding: "8px",
    display:"flex",
    alignItems:"center" 
  },
  bigCheckBox: {
    color: "#64748B",
    borderRadius: "6px",
    '&.Mui-checked': {
      color: "#CF9500",
    },
    '& .MuiSvgIcon-root': {
      fontSize: "20px",
    },
  }
};
// Customizable Area End
